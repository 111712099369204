/* eslint-disable no-undef */
'use strict';

var formHelpers = require('../checkout/formErrors');
var cleave = require('../components/cleave');
var scrollAnimate = require('base/components/scrollAnimate');
var summaryHelpers = require('base/checkout/summary');
var stripeCheckout = require('../stripe.checkout.js');

/**
 * shows the error message on the checkout page
 */
function showErrorOnCheckout() {
    $('.error-alert-dialog').removeClass('d-none');
}

/**
 * hides the error message on the checkout page
 */
function hideErrorOnCheckout() {
    $('.error-alert-dialog').addClass('d-none');
}

/**
* Updates the billing address form values within payment forms without any payment instrument validation
* @param {Object} order - the order model
*/
function updateBillingAddress(order) {
    var billing = order.billing;
    if (!billing.billingAddress || !billing.billingAddress.address) return;

    var form = $('form[name=dwfrm_billing]');
    if (!form) return;

    $('input[name$=_firstName]', form).val(billing.billingAddress.address.firstName);
    $('input[name$=_lastName]', form).val(billing.billingAddress.address.lastName);
    $('input[name$=_address1]', form).val(billing.billingAddress.address.address1);
    $('input[name$=_address2]', form).val(billing.billingAddress.address.address2);
    $('input[name$=_city]', form).val(billing.billingAddress.address.city);
    $('input[name$=_state]', form).val(billing.billingAddress.address.stateCode);
    $('input[name$=_postalCode]', form).val(billing.billingAddress.address.postalCode);
    $('input[name$=_state],input[name$=_state]', form)
        .val(billing.billingAddress.address.stateCode);
    $('input[name$=_country]', form).val(billing.billingAddress.address.countryCode.value);
    $('input[name$=_phone]', form).val(billing.billingAddress.address.phone);
    $('input[name$=_email]', form).val(order.orderEmail);
}

/**
 * Submit the payment form in submit order flow
 * @param {*} $form paymentform
 */
function submitPayment($form) {
    var url = $('#submit-payment').attr('action');
    var formData = cleave.serializeData($form);
    $.ajax({
        url: url,
        method: 'POST',
        dataType: 'json',
        data: formData,
        success: function (data) {
            if (data.error) {
                if (data.cartError && data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else if (data.cartError) {
                    if (data.errorMessage) {
                        $('.error-message').first().show();
                        $('.error-message-text').first().text(data.errorMessage);
                        scrollAnimate($('.error-message').first());
                    }
                }
                $.spinner().stop();
                if (data.serverErrors.length) {
                    showErrorOnCheckout(data.serverErrors[0]);
                }
            } else {
                hideErrorOnCheckout();
                var redirect = $('<form>')
                    .appendTo(document.body)
                    .attr({
                        method: 'POST',
                        action: data.continueUrl
                    });

                $('<input>')
                    .appendTo(redirect)
                    .attr({
                        name: 'orderID',
                        value: data.orderID
                    });

                $('<input>')
                    .appendTo(redirect)
                    .attr({
                        name: 'orderToken',
                        value: data.orderToken
                    });

                redirect.submit();
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Submit the order after validating the shipping and payment form
 * @param {*} $shippingForm shippingForm
 * @param {*} $paymentForm paymentForm
 */
function submitOrder($shippingForm, $paymentForm) {
    var url = $('.shipping-form').attr('action');
    formHelpers.clearPreviousErrors($shippingForm);
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $shippingForm.serialize(),
        success: function (data) {
            if (data.error) {
                $('.error-alert-dialog').removeClass('d-none');
                if (data.fieldErrors.length) {
                    data.fieldErrors.forEach(function (error) {
                        if (Object.keys(error).length) {
                            formHelpers.loadFormErrors($shippingForm, error);
                        }
                    });
                }
                $.spinner().stop();
                if (data.serverErrors.length) {
                    showErrorOnCheckout(data.serverErrors[0]);
                } else if (data.cartError && data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
            } else {
                hideErrorOnCheckout();
                $('.error-alert-dialog').addClass('d-none');
                $('.shipping-method-unavailable').addClass('d-none');
                $('.shipping-method-list').removeClass('d-none');
                if ($('.submit-payment-hostedStripe').length === 1) {
                    if ($('#payment-element').length) {
                        stripeCheckout.paymentSelections();
                        stripeCheckout.submitStripePayment($paymentForm, showErrorOnCheckout);
                    } else {
                        stripeCheckout.paymentSelections();
                    }
                } else {
                    submitPayment($paymentForm);
                }
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

$('body').on('click', '.connect-consultant', function () {
    var form = $(this).closest('form');
    var formData = $(form).serialize();
    var url = $('#show-consulatant').attr('action');
    formHelpers.clearPreviousErrors(form);
    var spinnerThreshold = parseInt(window.spinnerThreshold, 10);
    if (!($('.spinner').length > 0)) {
        var spinnerTimeout = setTimeout(function() {
            $.spinner().start();
        }, isNaN(spinnerThreshold) ? 0 : spinnerThreshold);
    }
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: formData,
        success: function (consultantData) {
            if (consultantData.error) {
                if (consultantData.fieldErrors.length) {
                    consultantData.fieldErrors.forEach(function (error) {
                        if (Object.keys(error).length) {
                            formHelpers.loadFormErrors(form, error);
                        }
                    });
                }
                clearTimeout(spinnerTimeout);
                $.spinner().stop();
            } else if (!consultantData.success && consultantData.consultant === '{}') {
                $('#consultantNotFoundModal').modal('show');
                clearTimeout(spinnerTimeout);
                $.spinner().stop();
            } else if (consultantData.success && !consultantData.consultant.isActive) {
                $('#consultantOfflineModal').modal('show');
                clearTimeout(spinnerTimeout);
                $.spinner().stop();
            } else {
                var consultant = consultantData.consultant;
                $('.hosted-consultant-details-title').addClass('d-none');
                $('.hosted-consultant-details.bottom').addClass('d-none');
                $('.unavailableIbc').addClass('d-none');
                $('.hosted-consultant-unavailable').addClass('d-none');
                $('.hosted-consultant-details.top').removeClass('d-none');
                $('.beautyconsultantName').find('span#beautyconsultantName').html(consultant.consultantName);
                $('.beautyconsultantDesignation').find('span#beautyconsultantDesignation').html(consultant.title);
                $('.ibcPhone p').find('span#ibcPhone').html(consultant.phone);
                $('.ibcLocation').find('span#ibcCountry').html(consultant.country);
                $('.ibcLocation').find('span#ibcCity').html(consultant.city);
                $('.ibcLocation').find('span#ibcState').html(consultant.state);
                $('.ibcLocation').find('span#ibcZip').html(consultant.zip);
                $('.beautyconsultantName').attr('value', consultant.consultantName);
                $('.beautyconsultantDesignation').attr('value', consultant.title);
                $('.ibcPhone').attr('value', consultant.phone);
                $('.beautyConsultantNumber').attr('value', consultant.consultantNumber);
                if (consultant.profileImage) {
                    $('.consultant-image').attr('src', consultant.profileImage);
                } else {
                    $('.consultant-image').removeAttr('src');
                }
                clearTimeout(spinnerTimeout);
                $.spinner().stop();
            }
            clearTimeout(spinnerTimeout);
            $.spinner().stop();
        },
        error: function () {
            clearTimeout(spinnerTimeout);
            $.spinner().stop();
        }
    });
    return false;
});

$('body').on('click', '.change-consultant-link', function () {
    $('.hosted-consultant-unavailable').removeClass('d-none');
    $('.hosted-consultant-details').addClass('d-none');
    $('.unavailableIbc').removeClass('d-none');
    $('.hosted-consultant-details-title').removeClass('d-none');
});

$('body').on('click', '.submit-payment-button', function () {
    $('.error-alert-dialog').addClass('d-none');
    $('.form-error-para').text('Please check your information and try again.');
    var $paymentForm = $('#submit-payment').closest('form');
    var $shippingForm = $('.shipping-form').closest('form');
    $('.page-body').spinner().start();
    submitOrder($shippingForm, $paymentForm);
    return false;
});

$('body').on('click', 'button.submit-payment-hostedStripe', function (event) {
    event.preventDefault();
    stripeCheckout.submitpaymenthostedStripe();
    return false;
});

$('#default-checkbox').on('change', function () {
    if ($(this).prop('checked')) {
        $('#billing-address-details').css('display', 'none');
    } else {
        $('#billing-address-details').css('display', 'block');
    }
});

$('body').on('change', '.shipping-form', function () {
    if ($('.shippingFirstName').val() && $('.shippingLastName').val() && $('.shippingZipCode').val()
            && $('.shippingAddressState').val() && $('.shippingAddressCity').val() && $('.shippingAddressOne').val()
            && $('.shippingAddressDistrict').attr('data-selected-district') && ($('.shippingEmail').val() || $('.customerEmail').val()) && $('.shippingPhoneNumber').val()) {
        if (!($('.spinner').length > 0)) {
            $('.page-body').spinner().start();
        }
        var $form = $(this);
        var $shippingForm = $('.shipping-form').closest('form');
        var url = $('.shipping-form').attr('action');
        formHelpers.clearPreviousErrors($form);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    if (data.fieldErrors.length) {
                        data.fieldErrors.forEach(function (error) {
                            if (Object.keys(error).length) {
                                formHelpers.loadFormErrors($shippingForm, error);
                            }
                        });
                        showErrorOnCheckout();
                    }
                    if (data.serverErrors.length) {
                        showErrorOnCheckout(data.serverErrors[0]);
                    }
                    $('#maincontent').spinner().stop();
                } else {
                    hideErrorOnCheckout();
                    updateBillingAddress(data.order);
                    summaryHelpers.updateTotals(data.order.totals);
                    $('.shipping-method-unavailable').addClass('d-none');
                    $('.shipping-method-list').removeClass('d-none');
                    if ($('#payment-element').length) {
                        stripeCheckout.initNewStripePaymentIntent();
                    } else {
                        $('#maincontent').spinner().stop();
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
    return false;
});

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.shipping-total-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.grand-total-sum').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.totals.discounts.length > 0) {
        $('.coupon-discounts').removeClass('d-none');
        $('.coupon-discounts').empty()
            .append(data.totals.couponDiscountsHtml);
    } else {
        $('.coupon-discounts').addClass('d-none');
    }
    $('.delete-coupon-confirmation-btn').attr('data-action', data.actionUrls.removeCouponLineItem);
    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 */
function updateAvailability(data) {
    var outOfStockItems = data.inventoryValidationResult ? data.inventoryValidationResult.outOfStockItems : [];

    if (outOfStockItems.length === 0) {
        $('.cart-has-out-of-stock-items').addClass('d-none');
        $('.checkout-btn').removeClass('disabled');
    } else {
        $('.cart-has-out-of-stock-items').removeClass('d-none');
        $('.checkout-btn').addClass('disabled');
    }

    $('.cart-item-out-of-stock-message').addClass('d-none');

    for (var i = 0; i < outOfStockItems.length; i++) {
        var sku = outOfStockItems[i];
        $('.cart-item-out-of-stock-message').filter('[data-sku="' + sku + '"]').removeClass('d-none');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}
/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            if ($('.cart').empty()) {
                $('.cart').empty().html(data.emptyCartHTML);
            }
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

$('body').on('cart:update', function (e, data) {
    if (data) {
        var basketModel = data.basket || data;
        updateAvailability(basketModel);
    }
});

$('body').on('click', '.cart-remove-out-of-stock-items-btn', function (e) {
    e.preventDefault();
    var buttonEl = $(this);
    var url = buttonEl.data('action');
    var items = buttonEl.attr('data-items') || '';

    $('body').trigger('cart:beforeUpdate');

    $.ajax({
        url: url,
        data: { outOfStockItems: items },
        type: 'post',
        context: this,
        dataType: 'json',
        success: function (data) {
            if (data.items.length === 0) {
                $('.cart').remove();
                $('.hosted-box').after(data.emptyCartHTML);
            } else {
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data);
                validateBasket(data);

                $('body').trigger('cart:update', data);

                var outOfStockItems = items.split(',');
                outOfStockItems.forEach(function (item) {
                    $('.cart-item-out-of-stock-message').filter('[data-sku="' + item + '"]').closest('.card.product-info').remove();
                });
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $(this).val(parseInt(preSelectQty, 10));
            }
        }
    });
});

// Increase product quantity on cart page
$('body').on('click', '.quantity-form > .button-plus', function () {
    var parentElm = $(this).parents('.quantity-form');
    $(this).addClass('clicked');
    setTimeout(function () {
        $('.clicked').removeClass('clicked');
    }, 100);
    var quantity = parentElm.find('.quantity').val();
    var maxVal = parentElm.find('.quantity').attr('max');
    if (quantity < maxVal) {
        quantity++;
        parentElm.find('.quantity').val(quantity);
        $(this).removeClass('disable');
        $(this).prop('disabled', false);
    }
});
// Decrease product quantity on cart page
$('body').on('click', '.quantity-form > .button-minus', function () {
    var parentElm = $(this).parents('.quantity-form');
    $(this).addClass('clicked');
    setTimeout(function () {
        $('.clicked').removeClass('clicked');
    }, 100);
    var quantity = parentElm.find('.quantity').val();
    var minVal = parentElm.find('.quantity').attr('min');
    if (quantity > minVal) {
        quantity--;
        parentElm.find('.quantity').val(quantity);
        $(this).removeClass('disable');
        $(this).prop('disabled', false);
    }
});

$('body').on('click', '.quantity-form > .button-plus , .button-minus ', function () {
    var parentElm = $(this).parents('.quantity-form');
    var preSelectQty = parentElm.find('.quantity').data('pre-select-qty');
    var quantity = parentElm.find('.quantity').val();
    var productID = parentElm.find('.quantity').data('pid');
    var url = parentElm.find('.quantity').data('action');
    var uuid = parentElm.find('.quantity').attr('data-uuid');
    var minVal = parentElm.find('.quantity').attr('min');
    var maxVal = parentElm.find('.quantity').attr('max');
    var productCard = $(this).closest('.cart-product-card');

    var urlParams = {
        pid: productID,
        quantity: quantity,
        uuid: uuid
    };
    url = appendToUrl(url, urlParams);

    parentElm.find('.button-plus').prop('disabled', true);
    parentElm.find('.button-minus').prop('disabled', true);
    parentElm.find('.button-plus').addClass('restricted');
    parentElm.find('.button-minus').addClass('restricted');

    $('body').trigger('cart:beforeUpdate');

    $.ajax({
        url: url,
        type: 'get',
        context: this,
        dataType: 'json',
        success: function (data) {
            $('.cart-product-items').empty().html(data.cartItemsHTML);
            $('.hosted-cart-alerts').empty().html(data.cartAlertsHTML);
            $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            if (data.couponError) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                $('.coupon-error-message').empty().append(data.couponErrorMessage);
                $('body').trigger('promotion:error', data);
            } else {
                $('.promo-code-form .form-control').removeClass('is-invalid');
                $('.coupon-error-message').empty();
            }
            productCard.find('.add-product-back').attr('data-quantity', quantity);
            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            updateAvailability(data);
            validateBasket(data);
            parentElm.find('.quantity').attr('data-pre-select-qty', quantity);

            $('body').trigger('cart:update', data);
            parentElm.find('.button-plus').prop('disabled', false);
            parentElm.find('.button-minus').prop('disabled', false);
            parentElm.find('.button-plus').removeClass('restricted');
            parentElm.find('.button-minus').removeClass('restricted');
            if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                location.reload();
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $(this).val(parseInt(preSelectQty, 10));
            }
        }
    });

    if (quantity === minVal) {
        $(this).addClass('disable');
        $(this).prop('disabled', true);
    } else if (parentElm.find('.button-minus').hasClass('disable')) {
        parentElm.find('.button-minus').removeClass('disable');
        parentElm.find('.button-minus').prop('disabled', false);
    }
    if (quantity === maxVal) {
        $(this).addClass('disable');
        $(this).prop('disabled', true);
    } else if (parentElm.find('.button-plus').hasClass('disable')) {
        parentElm.find('.button-plus').removeClass('disable');
        parentElm.find('.button-plus').prop('disabled', false);
    }
});

$('body').on('change', '.quantity-form > .quantity', function () {
    var parentElm = $(this).parents('.quantity-form');
    var preSelectQty = $(this).attr('data-pre-select-qty');
    var quantity = parentElm.find('.quantity').val();
    var productID = parentElm.find('.quantity').data('pid');
    var url = parentElm.find('.quantity').data('action');
    var uuid = parentElm.find('.quantity').attr('data-uuid');
    var minVal = parseInt($(this).attr('min'), 10);
    var maxVal = parseInt($(this).attr('max'), 10);
    var parsedQty = parseInt(quantity, 10);
    var productCard = $(this).closest('.cart-product-card');

    if (minVal > parsedQty) {
        $(this).val(parseInt(preSelectQty, 10));
        return;
    }

    if (maxVal <= parsedQty) {
        quantity = maxVal;
    }
    if (parsedQty === minVal) {
        parentElm.find('.button-minus').addClass('disable');
        parentElm.find('.button-minus').prop('disabled', true);
    } else {
        parentElm.find('.button-minus').removeClass('disable');
        parentElm.find('.button-minus').prop('disabled', false);
    }
    if (quantity === maxVal) {
        parentElm.find('.button-plus').addClass('disable');
        parentElm.find('.button-plus').prop('disabled', true);
    } else {
        parentElm.find('.button-plus').removeClass('disable');
        parentElm.find('.button-plus').prop('disabled', false);
    }

    if (preSelectQty === quantity) {
        $(this).val(parseInt(preSelectQty, 10));
        return;
    }

    var urlParams = {
        pid: productID,
        quantity: quantity,
        uuid: uuid
    };
    url = appendToUrl(url, urlParams);

    $('body').trigger('cart:beforeUpdate');

    $.ajax({
        url: url,
        type: 'get',
        context: this,
        dataType: 'json',
        success: function (data) {
            $('.cart-product-items').empty().html(data.cartItemsHTML);
            $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            if (data.couponError) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                $('.coupon-error-message').empty().append(data.couponErrorMessage);
                $('body').trigger('promotion:error', data);
            } else {
                $('.promo-code-form .form-control').removeClass('is-invalid');
                $('.coupon-error-message').empty();
            }
            productCard.find('.add-product-back').attr('data-quantity', quantity);
            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            updateAvailability(data, uuid);
            validateBasket(data);
            $(this).attr('data-pre-select-qty', quantity);

            $('body').trigger('cart:update', data);
            if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                location.reload();
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $(this).val(parseInt(preSelectQty, 10));
            }
        }
    });
});

$('body').on('keypress', '.quantity-form', function (e) {
    var txt = String.fromCharCode(e.which);
    if (!txt.match(/[0-9]/)) {
        return false;
    }

    return true;
});

$('.accordion-title').on('click', function () {
    var $this = $(this);

    var showhide = $this.find('.filterText');
    /*eslint no-unused-expressions: ["error", { "allowTernary": true }]*/
    showhide.text() === showhide.data('text-swap')
        ? showhide.text(showhide.data('text-original'))
        : showhide.text(showhide.data('text-swap'));

    $this.find('i').toggleClass('fa-angle-up fa-angle-down');
});

$('.orderDetailsLink').click(function () {
    $('html, body').animate({
        scrollTop: $('.order-details').offset().top
    }, 500);
});

$('body').on('click', '.remove-product', function (e) {
    e.preventDefault();

    var productID = $(this).data('pid');
    var url = $(this).data('action');
    var gtmData = $(this).data('gtmdata');
    var uuid = $(this).attr('data-uuid');
    var productCard = $(this).closest('.cart-product-card');
    var urlParams = {
        pid: productID,
        uuid: uuid
    };

    url = appendToUrl(url, urlParams);
    $('body').trigger('cart:beforeUpdate');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
            $('.hosted-cart-alerts').empty().html(data.cartAlertsHTML);
            if (data.couponError) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                $('.coupon-error-message').empty().append(data.couponErrorMessage);
                $('body').trigger('promotion:error', data);
            } else {
                $('.promo-code-form .form-control').removeClass('is-invalid');
                $('.coupon-error-message').empty();
            }
            if ($('body').find('.cart-product-card').hasClass('set--removed-in-cart')) {
                $('.product-line-item-revert').addClass('d-none');
            }
            $('.bonus-line-item-row').parent().remove();
            if (data.basket.cartBonusItemsHTML) {
                $('.cart-product-items').last().append(data.basket.cartBonusItemsHTML);
            }
            productCard.removeClass('set--in-cart');
            productCard.addClass('set--removed-in-cart');
            if ($('body').find(productCard).hasClass('set--removed-in-cart')) {
                productCard.find('.product-line-item-revert').removeClass('d-none');
            }
            updateCartTotals(data.basket);
            updateApproachingDiscounts(data.basket.approachingDiscounts);
            $('body').trigger('setShippingMethodSelection', data.basket);
            $('body').trigger('cart:update', data.basket);
            if (data.basket.items.length === 0) {
                $('.checkout-btn').addClass('disabled');
            } else {
                $('.checkout-btn').removeClass('disabled');
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
            }
        }
    });
});

$('body').on('click', '.add-product-back', function (e) {
    var addToCartUrl;

    $('body').trigger('product:beforeAddToCart', this);

    addToCartUrl = $(this).attr('data-url');
    var productCard = $(this).closest('.cart-product-card');
    var addProductBack = $(this);
    var oldUUID = $(this).attr('data-uuid');
    var lineItemTotal = productCard.find('.line-item-price');
    var itemTotalAmount = productCard.find('.item-total-amount');
    var lineItemMain = productCard.find('.product-line-item-main');

    var form = {
        pid: $(this).attr('data-pid'),
        quantity: $(this).attr('data-quantity')
    };

    $(this).trigger('updateAddToCartFormData', form);
    if (addToCartUrl) {
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: form,
            success: function (data) {
                if (data.error) {
                    $('.cart-product-items').empty().html(data.cartItemsHTML);
                    $('.hosted-cart-alerts').empty().html(data.cartAlertsHTML);
                    $('.coupons-and-promos').empty().append(data.cart.totals.discountsHtml);
                    updateCartTotals(data.cart);
                    updateApproachingDiscounts(data.cart.approachingDiscounts);
                    updateAvailability(data.cart);
                    validateBasket(data.cart);
                } else {
                    $('.coupons-and-promos').empty().append(data.cart.totals.discountsHtml);
                    $('.hosted-cart-alerts').empty().html(data.cartAlertsHTML);
                    productCard.find('.remove-product').attr('data-uuid', data.pliUUID);
                    productCard.find('.quantity').attr('data-uuid', data.pliUUID);
                    lineItemMain.removeClass('uuid-' + oldUUID).addClass('uuid-' + data.pliUUID);
                    lineItemTotal.removeClass('line-item-price-' + oldUUID).addClass('line-item-price-' + data.pliUUID);
                    itemTotalAmount.removeClass('item-total-' + oldUUID).addClass('item-total-' + data.pliUUID);
                    addProductBack.attr('data-uuid', data.pliUUID);
                    $('body').trigger('product:afterAddToCart', data);
                    $('.bonus-line-item-row').parent().remove();
                    productCard.removeClass('set--removed-in-cart');
                    productCard.addClass('set--in-cart');
                    if (data.cartBonusItemsHTML) {
                        $('.cart-product-items').last().append(data.cartBonusItemsHTML);
                    }
                    updateCartTotals(data.cart);
                    updateApproachingDiscounts(data.cart.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.cart);
                    $('body').trigger('cart:update', data.cart);
                    $('.promo-code-form .form-control').removeClass('is-invalid');
                    $('.coupon-error-message').empty();
                }
            },
            error: function () {
            }
        });
    }
});

$('.promo-code-form').submit(function (e) {
    e.preventDefault();
    $('.coupon-missing-error').hide();
    $('.coupon-error-message').empty();
    if (!$('.coupon-code-field').val()) {
        $('.promo-code-form .form-control').addClass('is-invalid');
        $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
        $('.coupon-missing-error').show();
        return false;
    }
    var $form = $('.promo-code-form');
    $('.promo-code-form .form-control').removeClass('is-invalid');
    $('.coupon-error-message').empty();
    $('body').trigger('promotion:beforeUpdate');

    $.ajax({
        url: $form.attr('action'),
        type: 'GET',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            if (data.error) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                $('.coupon-error-message').empty().append(data.errorMessage);
                $('body').trigger('promotion:error', data);
            } else {
                if ($('.cart-product-items').length > 0) {
                    $('.cart-product-items').empty().html(data.cartItemsHTML);
                }
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                if ($('.checkout-ordersummary').length > 0) {
                    summaryHelpers.updateTotals(data.totals);
                    $('.product-details').empty().append(data.orderSummaryItemsHTML);
                } else {
                    summaryHelpers.updateTotals(data.totals);
                }
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $('body').trigger('promotion:success', data);
            }
            $('.coupon-code-field').val('');
        },
        error: function (err) {
            $('body').trigger('promotion:error', err);
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
                $.spinner().stop();
            }
        }
    });
    return false;
});

$('body').on('click', '.remove-coupon', function (e) {
    e.preventDefault();

    var couponCode = $(this).data('code');
    var uuid = $(this).data('uuid');
    var action = $('#delete-coupon-confirmation-btn-id').data('action');
    var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
    var $productToRemoveSpan = $('.coupon-to-remove');

    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('code', couponCode);
    $deleteConfirmBtn.data('action', action);

    $productToRemoveSpan.empty().append(couponCode);
});

$('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
    e.preventDefault();

    var url = $(this).data('action');
    var uuid = $(this).data('uuid');
    var couponCode = $(this).data('code');
    var urlParams = {
        code: couponCode,
        uuid: uuid
    };

    url = appendToUrl(url, urlParams);

    $('body > .modal-backdrop').remove();

    $('body').trigger('promotion:beforeUpdate');
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            $('.coupon-uuid-' + uuid).remove();
            if ($('.cart-product-items').length > 0) {
                $('.cart-product-items').empty().html(data.cartItemsHTML);
            }
            if ($('.checkout-ordersummary').length > 0) {
                summaryHelpers.updateTotals(data.totals);
                $('.product-details').empty().append(data.orderSummaryItemsHTML);
            } else {
                summaryHelpers.updateTotals(data.totals);
            }
            updateApproachingDiscounts(data.approachingDiscounts);
            validateBasket(data);
            $('body').trigger('promotion:success', data);
        },
        error: function (err) {
            $('body').trigger('promotion:error', err);
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
            }
        }
    });
});

$('.alert-form-error').on('click', function () {
    $('.error-alert-dialog').addClass('d-none');
});

$('.hostedproductquantity').on('click', function () {
    $(this).select();
});

$(document).ready(function () {
    var $basketCreated = $('#basketCreated');
    if ($basketCreated && $basketCreated.val() === 'true') {
        $('body').trigger('product:addProductToEmptyCart');
    }
});

$(document).ready(function () {
    if ($('#payment-element').length) {
        stripeCheckout.initNewStripePaymentIntent();  // on page load.
    }
});

if (window.matchMedia('(max-width: 768px)').matches) {
    $('.productOrderSummary').addClass('show');
    $('.accordion-title').removeClass('collapsed');

    $('.accordion-title').on('click', function () {
        var $this = $(this);

        var showhide = $this.find('.hideandshow');
    /*eslint no-unused-expressions: ["error", { "allowTernary": true }]*/
        showhide.text() === showhide.data('text-swap')
            ? showhide.text(showhide.data('text-original'))
            : showhide.text(showhide.data('text-swap'));

        $this.find('i').toggleClass('fa-angle-up fa-angle-down');
    });
}
